
html {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.kiwi-wrap {
  font-size: 90%;
  line-height: 1.6em;
  font-family: Source Sans Pro, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  overflow: hidden;
}
.kiwi-wrap--monospace {
  font-family: Consolas, monaco, monospace;
  font-size: 80%;
}
.kiwi-workspace {
  position: relative;
  margin-left: 220px;
  left: 0;
  display: block;
  height: 100%;
  transition: left 0.2s, margin-left 0.2s;
}
.kiwi-workspace::before {
  position: absolute;
  content: '';
  right: 0;
  left: 0;
  top: 0;
  height: 7px;
  z-index: 0;
}
/* When the statebrowser opens as a draw, darken the workspace */
.kiwi-workspace::after {
  position: fixed;
  top: 0;
  right: 0;
  content: '';
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s;
  will-change: opacity;
}
.kiwi-workspace-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.kiwi-statebrowser {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  bottom: 0;
  transition: left 0.2s;
  z-index: 1;
}
.kiwi-container {
  position: absolute;
  top: 0;
  bottom: 40px;
  width: 100%;
}
.kiwi-mediaviewer {
  max-height: 70%;
  overflow: auto;
}
.kiwi-controlinput {
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  z-index: 2;
}
/* Small screen will cause the statebrowser to act as a drawer */
@media screen and (max-width: 769px) {
.kiwi-workspace {
    left: 0;
    margin-left: 0;
}
.kiwi-statebrowser {
    left: -200px;
}
.kiwi-wrap--statebrowser-drawopen .kiwi-statebrowser {
    left: 0;
}
.kiwi-wrap--statebrowser-drawopen .kiwi-workspace {
    left: 75%;
    width: 80%;
}
.kiwi-wrap--statebrowser-drawopen .kiwi-workspace::after {
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 10;
}
}
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0.7em 0 0.2em 0;
    margin: 0;
}

p {
    margin: 0.5em 0 1em 0;
}

div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.u-link {
    text-decoration: none;
    cursor: pointer;
}

.u-link:hover {
    text-decoration: underline;
}

.u-button {
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    padding: 0.4em 0.9em;
    border-radius: 4px;
    vertical-align: middle;
    text-align: center;
    outline: none;
}

.u-button:hover {
    opacity: 0.75;
}

.u-form {
    text-align: left;
}

.u-form label {
    display: block;
    line-height: 20px;
    margin: 0 0 10px 0;
}

.u-form label span {
    margin-right: 1em;
    display: inline-block;
    line-height: 25px;
    width: auto;
    font-weight: 500;
    text-align: left;
    max-width: none;
}

.u-form label.u-form-block span {
    width: auto;
    display: inline-block;
}

/* Style all form inputs */

.u-form input[type='checkbox'],
.u-form input[type='radio'] {
    float: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    border-radius: 1px;
    cursor: pointer;
    margin-left: 0;
    margin-right: 5px;
}

.u-form input[type='checkbox']::after,
.u-form input[type='radio']::after {
    position: absolute;
    content: '';
    left: 1px;
    top: 1px;
    width: 14px;
    height: 14px;
    z-index: 10;
    transition: all 0.3s;
    opacity: 0;
}

.u-form input[type='checkbox']:checked::after,
.u-form input[type='radio']:checked::after {
    position: absolute;
    content: '';
    left: 1px;
    top: 1px;
    width: 14px;
    height: 14px;
    z-index: 10;
    transition: all 0.3s;
    opacity: 1;
}

.u-form input[type="radio"] {
    float: right;
}

.u-form .u-input,
.u-form input[type='text'],
.u-form input[type='password'],
.u-form input[type='email'],
.u-form textarea {
    font-size: 0.9em;
    clear: both;
    width: 100%;
    height: 40px;
    padding: 3px 5px;
    line-height: 40px;
    box-sizing: border-box;
    border-radius: 1px;
    min-height: none;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: none;
}

.u-checkbox-wrapper {
    position: relative;
    padding-left: 30px;
}

.u-checkbox-wrapper input[type="checkbox"],
.u-checkbox-wrapper input[type="radio"] {
    position: absolute;
    margin: 0;
    top: 2px;
    left: 0;
}

.u-checkbox-wrapper label {
    margin-right: 0;
}

.u-form .u-submit {
    margin: 2em auto;
    display: block;
}

.kiwi-formatting-extras-quote {
    border: 1px solid #b5b5b5;
    padding: 0 3px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.05);
    font-family: monospace;
}

.kiwi-formatting-extras-invisible {
    font-size: 0;
}

.kiwi-formatting-extras-block {
    border-left: 4px solid #b5b5b5;
    padding: 1em;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.05);
    font-family: monospace;
    white-space: pre-wrap;
    display: inline-block;
}

/* Globally style the close icon */

.kiwi-close-icon {
    background-color: #fc6262;
    color: #fff;
    border-radius: 0 0 0 4px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 0.2em 0.4em;
}

.kiwi-close-icon:hover {
    background-color: #fe7575;
}

.irc-fg-colour-white { color: #fff; }

.irc-fg-colour-black { color: #000; }

.irc-fg-colour-blue { color: #00f; }

.irc-fg-colour-green { color: #0f0; }

.irc-fg-colour-light-red { color: #ff5959; }

.irc-fg-colour-brown { color: #743a00; }

.irc-fg-colour-purple { color: #a500ff; }

.irc-fg-colour-orange { color: #ff8c00; }

.irc-fg-colour-yellow { color: #ff0; }

.irc-fg-colour-light-green { color: #6fff6f; }

.irc-fg-colour-cyan { color: #0ff; }

.irc-fg-colour-light-cyan { color: #6dffff; }

.irc-fg-colour-light-blue { color: #59b4ff; }

.irc-fg-colour-pink { color: #f0f; }

.irc-fg-colour-grey { color: #bcbcbc; }

.irc-fg-colour-light-grey { color: #e2e2e2; }

.irc-bg-colour-white { background-color: #fff; }

.irc-bg-colour-black { background-color: #000; }

.irc-bg-colour-blue { background-color: #00f; }

.irc-bg-colour-green { background-color: #0f0; }

.irc-bg-colour-light-red { background-color: #ff5959; }

.irc-bg-colour-brown { background-color: #743a00; }

.irc-bg-colour-purple { background-color: #a500ff; }

.irc-bg-colour-orange { background-color: #ff8c00; }

.irc-bg-colour-yellow { background-color: #ff0; }

.irc-bg-colour-light-green { background-color: #6fff6f; }

.irc-bg-colour-cyan { background-color: #0ff; }

.irc-bg-colour-light-cyan { background-color: #6dffff; }

.irc-bg-colour-light-blue { background-color: #59b4ff; }

.irc-bg-colour-pink { background-color: #f0f; }

.irc-bg-colour-grey { background-color: #bcbcbc; }

.irc-bg-colour-light-grey { background-color: #e2e2e2; }

.kiwi-welcome-simple h2 {
    font-size: 1.7em;
    text-align: center;
    padding: 0;
    margin: 0.5em 0 1em 0;
}
.kiwi-welcome-simple-form {
    width: 90%;
    border-radius: 0.5em;
    padding: 1em;
}
.kiwi-welcome-simple-error {
    text-align: center;
    margin: 1em 0;
    padding: 0.3em;
}
.kiwi-welcome-simple-error span {
    display: block;
    font-style: italic;
}
.kiwi-welcome-simple-section-connection label {
    text-align: left;
    display: inline-block;
    margin-bottom: 0.8em;
    padding: 0 0.5em;
}
.kiwi-welcome-simple-section-connection .input-text input[type="text"] {
    margin-top: 5px;
    padding: 0.3em 1em;
    width: 100%;
    font-size: 1.1em;
    box-sizing: border-box;
}
.kiwi-welcome-simple .input-text {
    font-weight: 600;
    opacity: 0.6;
    font-size: 1.2em;
    margin-bottom: 0.8em;
}
.kiwi-welcome-simple-form input {
    padding: 0.5em;
}
.kiwi-welcome-simple-have-password input {
    font-size: 0.8em;
    margin: 0.8em 0;
    margin-top: 2px;
}
.kiwi-welcome-simple .g-recaptcha {
    margin-bottom: 10px;
}
.kiwi-welcome-simple .u-form label span {
    font-size: 1.1em;
    margin-left: 5px;
}
.kiwi-welcome-simple-start {
    font-size: 1.1em;
    cursor: pointer;
}
.kiwi-welcome-simple-start[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
}
.kiwi-welcome-simple-channel {
    margin-bottom: 0.8em;
}
.kiwi-welcome-simple-form .u-submit {
    width: 100%;
    line-height: 50px;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    text-shadow: none;
    margin: 0;
    transition: all 0.2s;
    border: none;
}
.kiwi-welcome-simple .help {
    position: absolute;
    bottom: 0.2em;
    font-size: 0.8em;
    width: 50%;
    text-align: center;
}
.kiwi-welcome-simple .help a {
    text-decoration: underline;
}

/* Styling the preloader */
.kiwi-welcome-simple .fa-spinner {
    position: absolute;
    top: 50%;
    z-index: 999;
    font-size: 100px;
    margin-top: -0.5em;
    left: 50%;
    margin-left: -40px;
}

/** Smaller screen... **/
@media screen and (max-width: 850px) {
.kiwi-welcome-simple-form {
        left: auto;
        margin: 20px auto 20px auto;
        z-index: 100;
        position: relative;
        top: auto;
        -ms-flex-item-align: start;
            align-self: flex-start;
}
.kiwi-welcome-simple p.help {
        position: absolute;
        bottom: 20px;
        width: 100%;
        color: #fff;
        z-index: 100;
}
.kiwi-welcome-simple p.help a {
        color: #fff;
}
.fa-spinner {
        position: absolute;
        left: 48%;
        top: 50%;
        margin-top: -50px;
        color: #fff;
}
}
@media (max-width: 400px) {
.kiwi-welcome-simple-form {
        width: 90%;
}
}


.kiwi-startup-common {
    height: 100%;
    text-align: center;
}
.kiwi-startup-common-section {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 25%;
    padding: 1em;
    box-sizing: border-box;
    overflow-y: auto;
}
.kiwi-startup-common-section-connection {
    left: 0;
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

/** Right side */
.kiwi-startup-common-section-info {
    right: 0;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 75%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-height: 100%;
}
.kiwi-startup-common-section-info-content {
    background: rgba(255, 255, 255, 0.74);
    margin: 2em;
    color: #1b1b1b;
    font-size: 1.5em;
    padding: 2em;
    line-height: 1.6em;
}
@media (max-width: 1325px) {
.kiwi-startup-common-section {
        width: 40%;
}
.kiwi-startup-common-section-info {
        width: 60%;
}
}
@media (min-width: 850px) {
    /** Closing - the wiping away of the screen **/
.kiwi-startup-common-section {
        transition: right 0.3s, left 0.3s;
}
.kiwi-startup-common--closing .kiwi-startup-common-section-connection {
        left: -50%;
}
.kiwi-startup-common--closing .kiwi-startup-common-section-info {
        right: -50%;
}
}

/** Smaller screen... **/
@media (max-width: 850px) {
.kiwi-startup-common {
        font-size: 0.9em;
        position: relative;
        min-height: 100%;
        transition: left 0.3s;
        left: 0;
}
.kiwi-startup-common-section-connection {
        min-height: 400px;
        width: 100%;
        position: static;
}
.kiwi-startup-common-section-info {
        min-height: 0;
        width: 100%;
        position: static;
}
.kiwi-startup-common-section-info-content {
        margin: 1em;
}

    /** Closing - the wiping away of the screen **/
.kiwi-startup-common--closing {
        left: -100%;
}
}

/** Even smaller screen.. probably phones **/
@media (max-width: 750px) {
.kiwi-startup-common {
        font-size: 0.9em;
        overflow-y: auto;
}
.kiwi-startup-common-section-info-content {
        margin: 0.5em;
}
}

/** Background /border switching between screen sizes **/

/* stylelint-disable  no-duplicate-selectors */
.kiwi-startup-common {
    background-size: 0;
    background-position: bottom;
}
.kiwi-startup-common-section-info {
    background-size: cover;
    background-position: bottom;
    border-left: 5px solid #42b992;
}
.kiwi-startup-common--no-bg .kiwi-startup-common-section-info {
    background-color: rgb(51, 51, 51);
}

/* stylint-enable */
@media (max-width: 850px) {
    /* Apply some flex so that the info panel fills the rest of the bottom screen */
.kiwi-startup-common {
        background-size: cover;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
}
.kiwi-startup-common-section {
        overflow-y: visible;
}
.kiwi-startup-common-section-info {
        background-size: 0;
        border-left: none;
        -webkit-box-flex: 1;
            -ms-flex: 1 0;
                flex: 1 0;
        display: block;
}
.kiwi-startup-common--no-bg .kiwi-startup-common-section-info {
        border-top: 5px solid #42b992;
}
}


.kiwi-welcome-znc h2 {
    font-size: 1.7em;
    text-align: center;
    padding: 0;
    margin: 0.5em 0 1.5em 0;
}
.kiwi-welcome-znc-error {
    text-align: center;
    margin: 1em 0;
    padding: 0.3em;
}
.kiwi-welcome-znc-error span {
    display: block;
    font-style: italic;
}
.kiwi-welcome-znc-form {
    width: 300px;
    background-color: #fff;
    border-radius: 0.5em;
    padding: 1em;
    border: 1px solid #ececec;
}
.kiwi-welcome-znc-form label {
    text-align: left;
    display: inline-block;
    margin-bottom: 1.5em;
}
.kiwi-welcome-znc-form input[type="text"] {
    font-size: 1em;
    margin-top: 5px;
    padding: 0.3em 1em;
    width: 100%;
    box-sizing: border-box;
}
.kiwi-welcome-znc .input-text,
.kiwi-welcome-znc .kiwi-welcome-znc-have-password input {
    margin-bottom: 1.5em;
}
.kiwi-welcome-znc input[type="password"] {
    height: 20px;
}
.kiwi-welcome-znc-have-password input:checked {
    margin-bottom: 0;
}
.kiwi-welcome-znc-start {
    font-size: 1.1em;
    cursor: pointer;
    width: 100%;
}
.kiwi-welcome-znc-start[disabled] {
    cursor: not-allowed;
}


.kiwi-customserver {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
            align-items: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
            justify-content: center;
}
.kiwi-customserver-start {
    font-size: 1.1em;
    cursor: pointer;
}
.kiwi-customserver-form {
    max-width: 300px;
    margin: 0 auto;
    max-height: 500px;
    overflow: hidden;
    border-radius: 0.5em;
    padding: 20px 1em;
}
.kiwi-customserver-form .input-text-label,
.kiwi-customserver-form .input-text--reveal-value .input-text-label {
    left: 0;
    font-weight: 600;
    font-size: 1em;
}
.kiwi-customserver .input-text,
.kiwi-customserver .kiwi-customserver-have-password input {
    margin-bottom: 1.5em;
}
.kiwi-customserver .input-text input {
    padding: 0.5em;
    font-size: 1.2em;
}
.kiwi-customserver .kiwi-customserver-have-password {
    margin-bottom: 20px;
}
.kiwi-customserver-have-password input:checked {
    margin-bottom: 0;
}
.kiwi-customserver-tls {
    cursor: pointer;
    top: 6px;
    color: #bfbfbf;
}
.kiwi-customserver-tls--enabled {
    color: green;
}
.kiwi-customserver-tls-lock {
    font-size: 1.2em;
}
.kiwi-customserver-tls-minus {
    color: red;
    font-size: 0.7em;
    top: 3px;
}
.kiwi-customserver-loader {
    margin-top: 1em;
    font-size: 2em;
}
.kiwi-customserver-channel {
    margin-top: 1em;
}
.kiwi-customserver-form .u-submit {
    width: 100%;
    height: 50px;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    text-shadow: none;
    margin: 0 0 20px 0;
    transition: all 0.2s;
    border: none;
    font-size: 1.2em;
    line-height: 51px;
}
.kiwi-customserver-server-types {
    font-size: 0.9em;
    text-align: center;
}
.kiwi-customserver-server-types a {
    margin: 0 1em;
}
.kiwi-customserver h2 {
    margin-bottom: 1.5em;
}
.kiwi-customserver h2 i {
    font-size: 0.8em;
    margin-left: 1em;
}
.kiwi-customserver--connecting h2 {
    transition: margin-top 0.7s;
    margin-top: 100px;
}
.kiwi-customserver-error {
    text-align: center;
    margin: 1em 0;
    padding: 0.3em;
}
.kiwi-customserver-error span {
    display: block;
    font-style: italic;
}
.connectingloader-enter-active,
.connectingloader-leave-active {
    transition: max-height 0.5s;
}
.connectingloader-enter,
.connectingloader-leave-to {
    max-height: 0;
}


.kiwi-startbnc-form label {
    text-align: left;
    display: block;
    margin-bottom: 1.5em;
}
.kiwi-startbnc-form input {
    font-size: 1em;
    margin-top: 5px;
    padding: 0.3em 1em;
    width: 100%;
    box-sizing: border-box;
}
.kiwi-startbnc-status {
    margin: 1em 0;
    overflow: hidden;
    max-height: 40px;
    transition: max-height 0.2s;
}
.kiwi-startbnc-status:empty {
    background: red;
    max-height: 0;
}
.kiwi-startbnc-start {
    font-size: 1.1em;
    cursor: pointer;
}
.kiwi-startbnc-form {
    width: 300px;
    background-color: #fff;
    border-radius: 0.5em;
    padding: 1em;
    border: 1px solid #ececec;
}


.kiwi-personal {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    text-align: center;
    padding-top: 1em;
    font-size: 1.2em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.kiwi-personal h1 {
    margin: 0 0 20px 0;
}
.kiwi-personal p {
    margin: 0 0 10px 0;
}
.kiwi-personal button {
    margin: 10px auto 0 auto;
    padding: 0 40px;
    font-size: 1em;
    line-height: 40px;
}

/* Only show the toggle state browser link if on a small screen */
.kiwi-personal-existing-networks {
    display: none;
}
@media screen and (max-width: 500px) {
.kiwi-personal-existing-networks {
        display: inherit;
}
}


.kiwi-statebrowser {
  box-sizing: border-box;
  z-index: 11;
  /* Must be at least 1 higher than the workspace :after z-index; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-right: none;
  width: 220px;
  text-align: center;
  overflow: hidden;
}
.kiwi-statebrowser h1 {
  width: 100%;
  font-size: 1em;
  opacity: 0.8;
  cursor: default;
  padding: 20px 0 27px 0;
}
.kiwi-statebrowser hr {
  width: 100%;
  margin: 0;
  opacity: 0.3;
}
/* User Settings */
.kiwi-statebrowser-appsettings {
  width: 90%;
  text-align: left;
  padding: 0 10px 0 10px;
  font-size: 0.8em;
  border-radius: 4px;
  box-sizing: border-box;
  opacity: 1;
  line-height: 35px;
  cursor: pointer;
  margin: 0 5%;
  font-weight: 500;
  letter-spacing: 1px;
  transition: all 0.3s;
  margin-bottom: 10px;
}
.kiwi-statebrowser-appsettings:hover {
  opacity: 1;
}
.kiwi-statebrowser-appsettings span {
  font-weight: 600;
}
.kiwi-statebrowser-appsettings i {
  float: right;
  line-height: 35px;
  font-size: 1.2em;
}
.kiwi-statebrowser-usermenu {
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 10px;
  padding-top: 10px;
}
.kiwi-statebrowser-usermenu-avatar {
  width: 50px;
  height: 50px;
  cursor: pointer;
  font-size: 1.5em;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 10px auto;
  transition: all 0.3s;
}
.kiwi-statebrowser-usermenu-body {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 0.8em;
  margin-bottom: 10px;
}
.kiwi-statebrowser-usermenu-body p {
  margin-bottom: 0;
}
/* Add network button */
.kiwi-statebrowser-newnetwork {
  width: 100%;
  position: static;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.kiwi-statebrowser-newnetwork a {
  width: 100%;
  padding: 0 10px;
  margin: 0;
  opacity: 1;
  line-height: 40px;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  background: none;
  text-align: left;
  position: relative;
  border-radius: 0;
  font-size: 0.9em;
  transition: all 0.3s;
  border: none;
}
.kiwi-statebrowser-newnetwork a i {
  position: absolute;
  right: 10px;
  line-height: 39px;
  font-size: 1.15em;
}
.kiwi-statebrowser-newnetwork a:hover {
  opacity: 1;
}
.kiwi-statebrowser-network .kiwi-statebrowser-network-header {
  float: left;
  width: 100%;
  line-height: 45px;
  text-align: left;
  position: relative;
}
.kiwi-statebrowser-network .kiwi-statebrowser-network-header a {
  text-align: left;
  padding: 0 0 0 10px;
  width: 100%;
  font-size: 1em;
  font-weight: 600;
}
/* Channel Styling */
.kiwi-statebrowser-channel {
  line-height: 30px;
  padding: 0 0 0 8px;
  transition: opacity 0.3s;
}
.kiwi-statebrowser-channel .kiwi-statebrowser-channel-name {
  text-align: left;
  font-weight: 600;
  font-size: 1em;
}
.kiwi-statebrowser-channel.kiwi-statebrowser-channel-active {
  font-weight: 600;
  opacity: 1;
}
/* New Channel Button */
.kiwi-statebrowser-newchannel {
  padding: 0;
  height: auto;
  width: 100%;
  border-top: none;
  box-sizing: border-box;
  margin: 1em 0;
}
.kiwi-statebrowser-newchannel a {
  width: 90%;
  padding: 0 10px 0 10px;
  line-height: 35px;
  font-size: 0.8em;
  font-weight: 500;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  background: none;
  text-align: left;
  position: relative;
  border-radius: 4px;
  margin: 0 5%;
  transition: all 0.3s;
}
.kiwi-statebrowser-newchannel a i {
  position: absolute;
  right: 10px;
  line-height: 35px;
  font-size: 1.2em;
}
.kiwi-statebrowser-newchannel a i:hover {
  opacity: 1;
}
.kiwi-statebrowser-usermenu .fa-caret-down {
  transition: all 0.3s;
}
.kiwi-statebrowser-usermenu--open .fa-caret-down {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.kiwi-statebrowser-switcher a {
  display: inline-block;
  width: 50%;
  padding: 5px 0;
  font-size: 1.2em;
  cursor: pointer;
  text-align: center;
}
.kiwi-statebrowser-availablenetworks-link a {
  cursor: pointer;
}
.kiwi-statebrowser-usermenu-body a:hover {
  text-decoration: underline;
}
.kiwi-statebrowser-scrollarea {
  height: auto;
  margin-bottom: 0;
  box-sizing: border-box;
  overflow-y: auto;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.kiwi-statebrowser-network {
  margin-bottom: 2em;
  overflow: hidden;
}
.kiwi-statebrowser-network:last-child {
  margin-bottom: 0;
}
.kiwi-statebrowser-options {
  position: absolute;
  bottom: 0;
  padding: 15px;
  height: 30px;
  /* some space on the right so it doesnt overlap the parent elements scrollbar */
  margin-right: 10px;
}
.kiwi-statebrowser-nonetworks {
  padding: 5px;
  text-align: center;
}
.kiwi-statebrowser-availablenetworks-toggle {
  cursor: pointer;
  text-align: center;
  padding: 5px 0;
}
.kiwi-statebrowser-availablenetworks-type {
  padding: 10px;
}
.kiwi-statebrowser-availablenetworks-name {
  text-align: center;
  font-weight: bold;
}
.kiwi-statebrowser-availablenetworks-networks {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s;
}
.kiwi-statebrowser-availablenetworks-networks--open {
  max-height: 500px;
}
.kiwi-statebrowser-newchannel-inputwrap {
  padding: 3px;
}
.kiwi-statebrowser-newchannel-inputwrap input {
  outline: none;
  border: none;
  display: block;
  width: calc(80%);
  margin-right: 30px;
}
.kiwi-statebrowser-newchannel-inputwrap i {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.kiwi-statebrowser-mobile-close {
  display: none;
}
.kiwi-statebrowser-availablenetworks-link {
  border-right: 15px solid red;
}
.kiwi-statebrowser-availablenetworks-link--connected {
  border-color: green;
}
.kiwi-statebrowser-channel-label-transition-enter-active,
.kiwi-statebrowser-channel-label-transition-leave-active {
  transition: opacity 0.1s;
}
.kiwi-statebrowser-channel-label-transition-enter,
.kiwi-statebrowser-channel-label-transition-leave-active {
  opacity: 0;
}
.kiwi-statebrowser-newchannel-inputwrap--focus {
  opacity: 1;
}
@media screen and (max-width: 769px) {
.kiwi-statebrowser {
    left: -100%;
    padding-top: 0;
    z-index: 1000;
}
.kiwi-wrap.kiwi-wrap--statebrowser-drawopen .kiwi-statebrowser {
    width: 75%;
    left: 0;
    z-index: 100;
}
.kiwi-header {
    text-align: center;
}
.kiwi-container-toggledraw-statebrowser-messagecount {
    width: 30px;
    color: #000;
    font-weight: 600;
    max-height: 49.5px;
}
.kiwi-statebrowser-newchannel a {
    margin-right: 2.5%;
    margin-left: 2.5%;
    width: 95%;
}
.kiwi-statebrowser-mobile-close {
    width: 100%;
    color: #fff;
    display: block;
    padding: 0 10px;
    font-weight: 600;
    background: #42b992;
    box-sizing: border-box;
    margin-bottom: 0;
    text-transform: uppercase;
    line-height: 45px;
    height: 45px;
}
.kiwi-statebrowser-mobile-close span {
    float: left;
}
.kiwi-statebrowser-mobile-close i {
    float: right;
    font-size: 1.2em;
    line-height: 45px;
}
.kiwi-statebrowser-usermenu {
    position: relative;
}
}

.kiwi-channel-options-header {
    text-align: left;
    padding: 0 0 0 10px;
    margin: 0;
    opacity: 1;
    cursor: default;
    float: left;
    width: 100%;
    box-sizing: border-box;
}
.kiwi-channel-options-header span {
    padding: 5px 0;
    float: left;
    font-size: 1.2em;
    font-weight: 600;
}
.kiwi-statebrowser-network-header {
    display: block;
    padding-right: 0;
    position: relative;
    overflow: hidden;
    height: auto;
}
.kiwi-statebrowser-network-name {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    font-size: 1.1em;
    text-align: center;
    display: block;
    padding: 4px 0;
    box-sizing: border-box;
}
.kiwi-network-name-hover-icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 45px;
    z-index: 2;
    width: 45px;
    text-align: center;
    line-height: 45px;
}
.kiwi-network-name-options {
    position: absolute;
    top: 0;
    height: 45px;
    z-index: 10;
    right: -300px;
    transition: all 0.15s;
}
.kiwi-statebrowser-network-header:hover .kiwi-network-name-options {
    right: 0;
    opacity: 1;
}
.kiwi-network-name-options .option-button {
    float: right;
    width: 35px;
    transition: all 0.15s;
    padding: 0;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
}
.kiwi-statebrowser-network-toggable-area--collapsed {
    display: none;
}
.kiwi-statebrowser-network-status {
    text-align: center;
    padding: 4px 4px 6px 4px;
    overflow: hidden;
    position: relative;
    height: 1.5em;
    font-size: 0.9em;
}

/* During DOM entering and leaving */
.kiwi-statebrowser-network-status-transition-enter-active,
.kiwi-statebrowser-network-status-transition-leave-active {
    transition: height 0.7s, padding 0.7s;
}
.kiwi-statebrowser-network-status-transition-enter,
.kiwi-statebrowser-network-status-transition-leave-active {
    height: 0;
    padding: 0;
}
.kiwi-statebrowser-channel {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.kiwi-statebrowser-channel:hover .kiwi-statebrowser-channel-name {
    text-decoration: underline;
}
.kiwi-statebrowser-channel-name {
    cursor: pointer;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    transition: padding 0.1s, border 0.1s;
}
.kiwi-statebrowser-channel-labels {
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 0;
    top: 0;
}
.kiwi-statebrowser-channel-label {
    display: inline-block;
    padding: 0 10px;
    line-height: 30px;
    height: 30px;
    margin: 0;
    border-radius: 5px 0 0 5px;
    font-weight: 600;
}
.kiwi-statebrowser-channel-label:hover {
    opacity: 1;
}
.kiwi-statebrowser-channel-label-transition-enter-active,
.kiwi-statebrowser-channel-label-transition-leave-active {
    transition: opacity 0.1s;
}
.kiwi-statebrowser-channel-label-transition-enter,
.kiwi-statebrowser-channel-label-transition-leave-active {
    opacity: 0;
}
.kiwi-statebrowser-channel-settings {
    display: block;
    height: 100%;
    width: 35px;
    opacity: 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
    z-index: 10;
}
.kiwi-statebrowser-channel-leave {
    float: right;
    opacity: 0;
    width: 35px;
    cursor: pointer;
    margin-right: 0;
    transition: all 0.3s;
    z-index: 10;
}
.kiwi-statebrowser-channel:hover .kiwi-statebrowser-channel-settings,
.kiwi-statebrowser-channel:hover .kiwi-statebrowser-channel-leave {
    opacity: 1;
}

/* Add channel input */
.kiwi-statebrowser-newchannel-inputwrap {
    float: left;
    width: 100%;
    position: relative;
    border-radius: 3px;
    opacity: 1;
    transition: opacity 0.3s;
    background: none;
    padding: 0;
    margin: 0 0 0 0;
    box-sizing: border-box;
}
.kiwi-statebrowser-newchannel-inputwrap input[type='text'] {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    line-height: 40px;
    font-size: 0.8em;
    box-sizing: border-box;
    border: none;
    margin: 0;
    border-radius: 0;
    min-height: none;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: none;
}
.kiwi-statebrowser-newchannel-inputwrap--focus {
    opacity: 1;
}

/* Channel search input */
.kiwi-statebrowser-channelfilter {
    float: left;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    opacity: 1;
    transition: all 0.3s;
    margin-bottom: 0;
}
.kiwi-statebrowser-channelfilter:hover {
    opacity: 1;
}
.kiwi-statebrowser-channelfilter input {
    width: 100%;
    height: 42px;
    line-height: 42px;
    padding: 0 15px;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
}
.kiwi-statebrowser-channelfilter p {
    text-align: center;
    font-size: 0.9em;
    margin: 10px 0 10px 0;
    cursor: pointer;
    transition: all 0.3s;
}
.kiwi-statebrowser-channelfilter p:hover {
    text-decoration: underline;
}
@media screen and (max-width: 769px) {
.kiwi-statebrowser-network-header .kiwi-network-name-options {
        right: 0;
        opacity: 1;
}
.kiwi-statebrowser-channel-name {
        line-height: 40px;
}
.kiwi-statebrowser-channel .kiwi-statebrowser-channel-settings,
    .kiwi-statebrowser-channel .kiwi-statebrowser-channel-leave {
        opacity: 1;
        line-height: 40px;
}
}


.kiwi-buffersettings {
    box-sizing: border-box;
}
.kiwi-buffersettings-alerts {
    margin-bottom: 1em;
}


.kiwi-appsettings {
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  padding: 8px 0 0 0;
  margin-top: -7px;
}
.kiwi-appsettings .u-form {
  width: 100%;
  overflow: hidden;
}
.kiwi-appsettings-setting-theme span {
  margin-left: 0;
}
.kiwi-appsettings-setting-theme select {
  float: right;
}
.kiwi-appsettings-setting-showjoinpart span {
  max-width: none;
}
.kiwi-appsettings-full span {
  margin: 0;
  width: 100%;
}
.kiwi-appsettings-aliases {
  padding: 1em 20px 2em 20px;
}
.u-form .kiwi-appsettings-setting-scrollback {
  line-height: 40px;
}
.u-form .kiwi-appsettings-setting-scrollback input {
  box-sizing: border-box;
  line-height: 30px;
  height: 40px;
  border: 1px solid;
  float: left;
  margin-right: 10px;
  width: 80px;
}
.kiwi-appsettings-tab-container {
  width: 100%;
}
.kiwi-appsettings-close {
  float: right;
}
.kiwi-appsettings .u-form label {
  display: block;
}
.kiwi-appsettings .u-form label span {
  text-align: left;
  margin-left: 0;
}
.kiwi-appsettings-theme-reload {
  margin-left: 1em;
  float: right;
  cursor: pointer;
}
.kiwi-settings-aliases-input {
  height: auto;
  min-height: 300px;
  min-height: 400px;
  line-height: normal;
  width: 100%;
  max-width: 100%;
  resize: vertical;
}
.kiwi-appsettings-block {
  max-width: 400px;
  width: auto;
  display: block;
  box-sizing: border-box;
  margin: 20px auto 20px auto;
}
.kiwi-appsettings-block h3 {
  width: 100%;
  line-height: 45px;
  padding: 0 10px;
  box-sizing: border-box;
}
.kiwi-appsettings-section {
  padding: 10px;
}
.kiwi-appsettings-block-aliases {
  max-width: 750px;
}
.kiwi-appsettings-title {
  display: block;
  cursor: pointer;
  padding: 0 10px;
  margin: -1px 0 0 0;
  font-weight: 600;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  text-transform: uppercase;
  line-height: 47px;
  text-align: right;
  transition: background 0.3s;
}
.kiwi-appsettings-title h2 {
  padding: 10px 0 11px 20px;
  width: auto;
  float: left;
}
.kiwi-appsettings-title a {
  float: right;
  position: static;
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 1.4em;
}
.kiwi-appsettings-title i {
  margin-left: 10px;
  font-size: 1.5em;
  float: right;
  line-height: 47px;
}
@media screen and (max-width: 769px) {
.kiwi-appsettings {
    width: 100%;
    z-index: 999;
    position: fixed;
    left: 0;
    transition: left 0.5s;
}
.kiwi-appsettings .kiwi-appsettings-block {
    width: 90%;
}
.kiwi-appsettings .u-form label span {
    width: auto;
    margin-right: 0;
    display: inline-block;
}
}

.kiwi-settings-aliases-input {
    width: 80%;
    height: 200px;
    font-size: 0.8em;
    line-height: 1.4em;
    font-family: monospace;
}
.kiwi-settings-aliases-showhelp {
    display: block;
}
.kiwi-settings-aliases-help {
    padding: 1em;
    margin: 1em 0;
    border: 1px dashed gray;
}
.kiwi-settings-aliases-help em {
    padding: 1px 2px;
}


.kiwi-container {
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    top: 4px;
}

/* When the sidebar is open we will put a shadow over the text area */
.kiwi-header {
    z-index: 2;

    /* IE 11 breaks when using the shorthand flex syntax here */
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 1;
        flex-shrink: 1;
}
.kiwi-sidebar {
    position: absolute;
    right: -443px;
    top: -4px; /* Push the top over the top page border */
    bottom: 0;
    width: 443px;
    max-width: 443px;
    z-index: 3;
    transition: right 0.2s, width 0.2s;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.kiwi-container--sidebar-open .kiwi-sidebar {
    right: 0;
}
.kiwi-container--sidebar-pinned .kiwi-sidebar {
    right: 0;
    top: 0;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    position: relative;
    border-left-width: 1px;
    border-left-style: solid;
    max-width: 430px;
    z-index: 1;
}
.kiwi-container-content {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    overflow: hidden;
}
.kiwi-messagelist {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.kiwi-serverview {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.kiwi-container--no-sidebar .kiwi-header,
.kiwi-container--no-sidebar .kiwi-messagelist {
    margin-right: 0;
}
.kiwi-container-toggledraw-statebrowser,
.kiwi-container-toggledraw-sidebar {
    display: none;
    width: 50px;
    position: absolute;
    top: 0;
    height: 45px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    font-size: 1.6em;
    line-height: 50px;
}
.kiwi-container-toggledraw-statebrowser {
    left: 0;
}
.kiwi-container-toggledraw-sidebar {
    right: 0;
}
.kiwi-container-toggledraw-sidebar--disabled {
    cursor: default;
}
.kiwi-container-toggledraw-statebrowser-messagecount {
    position: relative;
    font-size: 0.6em;
    border-radius: 3px;
    line-height: 2em;
    box-sizing: border-box;
    top: 10px;
    z-index: 3;
    white-space: nowrap;
    left: 6px;
    width: 37px;
    padding: 0;
}
.kiwi-container-empty {
    text-align: center;
    padding: 1em;
}
.kiwi-container-empty .u-button {
    border-radius: 3px;
    font-weight: 500;
    line-height: 50px;
    padding: 0 14px;
}
@media screen and (max-width: 1500px) {
.kiwi-container--sidebar-pinned .kiwi-sidebar {
        max-width: 350px;
}
}
@media screen and (max-width: 769px) {
.kiwi-header {
        margin-left: 50px;
        margin-right: 50px;
        max-height: 50px;
}
.kiwi-container-toggledraw-statebrowser,
    .kiwi-container-toggledraw-sidebar {
        display: block;
}
}


.kiwi-header {
  padding: 0;
  transition: all 0.3s;
  line-height: 10px;
  box-sizing: border-box;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.kiwi-header--showall {
  height: auto;
  max-height: 100%;
  overflow-y: auto;
}
/* why this hover? */
.kiwi-header:hover {
  max-height: none;
}
.kiwi-header:hover .kiwi-header-topic {
  display: block;
}
.kiwi-header-topic {
  padding: 0;
  line-height: normal;
  max-width: none;
  width: 100%;
  float: right;
  box-sizing: border-box;
  height: auto;
  text-align: left;
}
.kiwi-header-topic > div {
  height: auto;
  font-size: 0.8;
  cursor: default;
  padding: 10px 20px;
}
.kiwi-header-name {
  font-weight: bold;
  cursor: default;
  margin: 0;
  margin-right: 0.5em;
  padding: 0.5em 20px;
  opacity: 1;
  font-size: 20px;
  line-height: normal;
  float: left;
}
.kiwi-header-options {
  width: auto;
  display: inline-block;
  float: right;
}
.kiwi-header-option {
  border: none;
  float: left;
  background: none;
  display: inline-block;
  font-size: 0.8em;
  opacity: 0.9;
  font-weight: 900;
}
.kiwi-header-option a {
  float: left;
  padding: 0 10px;
  line-height: 45px;
  display: block;
  font-weight: 600;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.3s;
}
.kiwi-header-option a:hover {
  opacity: 1;
}
.kiwi-header-option i {
  margin-right: 10px;
  font-size: 1.2em;
  float: left;
  line-height: 45px;
}
.kiwi-header-option--active {
  opacity: 1;
}
.kiwi-header-option--active a {
  opacity: 1;
}
.kiwi-header-option-leave {
  opacity: 1;
  margin: 0;
  transition: all 0.3s;
}
.kiwi-header-option-leave i {
  margin: 0;
}
.kiwi-header-option-unpinsidebar i {
  margin: 0;
}
/* The not joined button */
.kiwi-header-notjoined {
  border-radius: 0;
  display: inline-block;
  margin: 0 auto;
  float: right;
}
.kiwi-header-notjoined .u-link {
  font-weight: 600;
  line-height: 45px;
  padding: 0 25px;
  border-radius: 0;
  transition: all 0.3;
}
.kiwi-header-server-settings {
  display: inline;
}
.kiwi-header-server-connection {
  float: right;
  padding-right: 10px;
  line-height: 46px;
}
.kiwi-header-server-connection .u-button {
  float: right;
  line-height: 35px;
  padding: 0 1em;
  margin: 4px 0;
  border-radius: 4px;
}
.kiwi-header-options .u-button {
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 600;
  letter-spacing: 0.2em;
  padding: 0.5em 1.7em;
  line-height: 2em;
  border-radius: 0.4em;
}
.kiwi-header-join-channel-button {
  border-radius: 0.3em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: inherit;
  height: auto;
  display: inline-block;
  padding: 0.2em 1em;
  font-size: 0.8em;
}
.kiwi-header-close-buffersettings {
  float: right;
}
.kiwi-header-buffersettings {
  padding: 5px;
  margin-top: 1em;
}
.kiwi-header-prompt {
  position: absolute;
  right: 0;
  top: 46px;
  /* z-index 1 higher than the sidebar */
  z-index: 11;
}
.kiwi-header-prompttrans-enter,
.kiwi-header-prompttrans-leave-to {
  top: -45px;
}
.kiwi-header-prompttrans-enter-to,
.kiwi-header-prompttrans-leave {
  top: 46px;
}
.kiwi-header-prompttrans-enter-active,
.kiwi-header-prompttrans-leave-active {
  transition: top 0.2s;
}
@media screen and (max-width: 769px) {
.kiwi-container-toggledraw-statebrowser {
    border-bottom: none;
}
.kiwi-header {
    margin-right: 0;
    overflow: visible;
    height: auto;
    max-height: none;
    padding-left: 0;
    margin-left: 0;
}
.kiwi-header .kiwi-header-name {
    line-height: normal;
    padding-left: 60px;
}
.kiwi-header-option a i {
    margin-right: 0;
}
.kiwi-header-option .fa-info {
    display: block;
    font-size: 1.5em;
    padding: 0;
    opacity: 0.8;
    line-height: 45px;
}
.kiwi-header-option span {
    display: none;
}
.kiwi-header-server-connection .u-button {
    line-height: 32px;
    margin: 7px 0 0 0;
    opacity: 1;
    font-weight: 600;
}
.kiwi-header-notjoined {
    height: 45px;
    margin: 0;
}
.kiwi-header-notjoined .kiwi-header-join-channel-button {
    padding-left: 10px;
    padding-right: 10px;
}
.kiwi-containerheader-hidetext {
    display: none;
}
}

.kiwi-channelbanlist-table {
  width: 100%;
  border-collapse: collapse;
  line-height: 20px;
  margin-top: 10px;
}
.kiwi-channelbanlist-table-bannedat {
  min-width: 150px;
}
.kiwi-channelbanlist-table-actions {
  min-width: 50px;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
  z-index: 1;
}

.kiwi-sidebar {
  background: #fff;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.kiwi-sidebar.kiwi-sidebar-section-settings {
  width: 500px;
  max-width: 500px;
}
.kiwi-sidebar .u-form textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 80px;
  resize: vertical;
}
.kiwi-sidebar-options {
  display: block;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  text-transform: uppercase;
  line-height: 50px;
  vertical-align: top;
}
.kiwi-sidebar-options .kiwi-sidebar-pin {
  position: absolute;
  padding: 0 10px;
  height: 100%;
  line-height: 52px;
  z-index: 1;
  transition: background 0.3s;
}
.kiwi-sidebar-options .kiwi-sidebar-close {
  width: 100%;
  display: inline-block;
  padding: 0 20px 0 40px;
  text-align: right;
  box-sizing: border-box;
  transition: background 0.3s;
}
.kiwi-sidebar-options .kiwi-sidebar-close i {
  margin-left: 10px;
  font-size: 1.5em;
  line-height: 47px;
}
.kiwi-sidebar-buffersettings {
  overflow: hidden;
  height: 100%;
}
.kiwi-sidebar-buffersettings .u-tabbed-content {
  padding: 1em;
}
.kiwi-sidebar-userbox {
  overflow: hidden;
  height: 100%;
}
.kiwi-sidebar-settings {
  margin-bottom: 20px;
}
.kiwi-sidebar-settings label {
  display: block;
}
@-webkit-keyframes settingstransition {
from {
    margin-top: 50px;
}
to {
    margin-top: 100px;
}
}
@keyframes settingstransition {
from {
    margin-top: 50px;
}
to {
    margin-top: 100px;
}
}
@-webkit-keyframes nicklisttransition {
from {
    height: 0;
}
to {
    height: 100%;
}
}
@keyframes nicklisttransition {
from {
    height: 0;
}
to {
    height: 100%;
}
}
.kiwi-channelbanlist-empty {
  margin-top: 10px;
}
@media screen and (max-width: 769px) {
.kiwi-sidebar .u-tabbed-view-tab {
    width: 100%;
}
.kiwi-sidebar .u-tabbed-view-tab.u-tabbed-view-tab--active {
    border-bottom: 3px solid #42b992;
    margin-bottom: 0;
}
.kiwi-sidebar .u-form input[type="checkbox"] {
    margin-right: 4px;
}
.kiwi-sidebar .u-form label span {
    margin-right: 0;
    margin-left: 0;
}
.kiwi-container--sidebar-open .kiwi-sidebar {
    width: 100%;
    max-width: 100%;
}
.kiwi-sidebar-buffersettings {
    padding-bottom: 10px;
}
.kiwi-channelbanlist {
    float: left;
    width: 100%;
}
.kiwi-channelbanlist-table {
    margin-top: 30px;
}
.kiwi-channelbanlist .u-form {
    line-height: 10px;
}
.kiwi-sidebar-options {
    line-height: 47px;
}
}

.kiwi-sidebar.kiwi-sidebar-section-user {
  right: 0;
  width: 380px;
}
.kiwi-userbox {
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
}
.kiwi-userbox-header {
  position: relative;
  padding: 0.5em 1em 0.5em 5em;
  overflow: hidden;
}
.kiwi-userbox-header h3 {
  width: 100%;
  padding: 0;
  cursor: default;
}
.fa-user.kiwi-userbox-icon {
  position: absolute;
  left: 0.5em;
  top: 0.25em;
  font-size: 3em;
}
.kiwi-userbox-usermask {
  width: 100%;
  cursor: default;
}
.kiwi-userbox-basicinfo {
  width: 100%;
  margin: 0;
  display: block;
  padding: 1em 1em 1em 1.4em;
  box-sizing: border-box;
}
.kiwi-userbox-basicinfo-title,
.kiwi-userbox-basicinfo-data {
  display: block;
  width: 100%;
  cursor: default;
  margin: 0;
}
.kiwi-userbox-basicinfo-title {
  font-size: 1em;
  line-height: 1em;
  padding: 0;
  text-align: left;
  opacity: 0.5;
  font-weight: 900;
}
.kiwi-userbox-basicinfo-data {
  margin-bottom: 1em;
  font-weight: normal;
  font-weight: 100;
  opacity: 1;
}
.kiwi-userbox-actions {
  width: 100%;
  padding: 1em;
  text-align: center;
  margin: 0;
  box-sizing: border-box;
}
.kiwi-userbox-actions .kiwi-userbox-action {
  display: inline-block;
  border: 1px solid #000;
  padding: 0.5em 1em;
  color: #000;
  cursor: pointer;
  margin: 0 auto 20px auto;
  transition: all 0.3s;
  border-radius: 3px;
}
.kiwi-userbox-actions .kiwi-userbox-action:hover {
  background-color: #000;
  color: #fff;
}
.kiwi-userbox-actions label {
  display: block;
  cursor: pointer;
}
.kiwi-userbox-actions label span {
  text-align: left;
  width: auto;
}
.kiwi-userbox-opactions {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin: 0 0 1em 0;
  border-top: 1px solid;
  padding: 1em;
}
.kiwi-userbox-opactions label {
  width: 100%;
  font-size: 1.2em;
  font-weight: 600;
  display: block;
  margin-bottom: 0.7em;
}
.kiwi-userbox-opactions label select {
  display: block;
  clear: both;
  padding: 10px;
  border-radius: 0.25em;
  box-shadow: none;
  border: 1px solid;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}
.kiwi-userbox-opaction {
  width: 100%;
  padding: 0 1em;
  text-align: left;
  border: none;
  line-height: 2.8em;
  font-size: 1em;
}
.kiwi-userbox-opaction i {
  margin-right: 0.3em;
}
.kiwi-userbox-actions a {
  margin-right: 1em;
}
.kiwi-userbox-whois {
  line-height: 1.4em;
  padding: 1em;
  width: 90%;
  margin: 0 5% 20px 5%;
  background: none;
  box-sizing: border-box;
}
.kiwi-userbox-whois-line {
  display: block;
}
.kiwi-userbox-ignoreuser {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.kiwi-userbox-ignoreuser span {
  /* This fixes a vertical align issue between the checkbox and span */
  float: right;
}
@media screen and (max-width: 769px) {
.kiwi-container--sidebar-open .kiwi-sidebar-userbox {
    width: 100%;
}
.kiwi-userbox {
    left: 0;
    right: 0;
    bottom: 40px;
    top: auto;
    max-width: 100%;
    border-width: 1px 0;
}
.kiwi-userbox .kiwi-userbox-header {
    padding-left: 10px;
}
.kiwi-userbox .kiwi-userbox-header i {
    display: none;
}
.kiwi-userbox .kiwi-userbox-basicinfo {
    padding: 10px 10px;
    margin-bottom: 20px;
}
.kiwi-userbox-actions {
    padding: 0;
    width: 100%;
    box-sizing: border-box;
}
.kiwi-userbox-actions .kiwi-userbox-action {
    width: 200px;
    clear: both;
    display: block;
}
}
/* Adjust the sidebars width when this nicklist is in view */
.kiwi-sidebar.kiwi-sidebar-section-nicklist {
  max-width: 250px;
  width: 250px;
}
.kiwi-nicklist {
  overflow: hidden;
  box-sizing: border-box;
  min-height: 100px;
  margin: auto;
  width: 100%;
  padding-bottom: 1px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.kiwi-nicklist-usercount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0.5em 10px;
  cursor: default;
  box-sizing: border-box;
}
.kiwi-nicklist-usercount span {
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.kiwi-nicklist-info {
  float: right;
  width: 100%;
  margin: auto;
  height: 43px;
  box-sizing: border-box;
  position: relative;
  font-size: 0.9em;
  padding-bottom: 0;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.kiwi-nicklist-info input {
  text-align: left;
  float: left;
  width: 100%;
  border: none;
  padding: 0 1em;
  height: 43px;
  line-height: 43px;
  font-weight: normal;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: 0 0;
  outline: 0;
}
.kiwi-nicklist-info .fa.fa-search {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  color: #000;
  opacity: 0.5;
  line-height: normal;
  font-size: 1.2em;
  right: 20px;
  margin-right: 0;
}
.kiwi-nicklist-users {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  max-height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1 auto;
          flex: 1 auto;
  list-style: none;
  line-height: 1.2em;
}
.kiwi-nicklist-user {
  line-height: 40px;
  padding: 0 1em;
  margin: 0;
  position: relative;
  box-sizing: border-box;
  transition: background 0.3s;
}
.kiwi-nicklist-messageuser {
  position: absolute;
  content: '\F075';
  right: 1em;
  font-family: fontAwesome, sans-serif;
  top: 50%;
  margin-top: -1.5em;
}
.kiwi-nicklist-messageuser:hover {
  cursor: pointer;
}
.kiwi-nicklist-info i.fa-search {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 25px;
  cursor: pointer;
  line-height: 50px;
}
.kiwi-nicklist-user-nick {
  font-weight: bold;
  cursor: pointer;
}
@media screen and (max-width: 759px) {
.kiwi-sidebar.kiwi-sidebar-section-nicklist {
    width: 100%;
    max-width: 380px;
}
}

.kiwi-messagelist {
  overflow-y: auto;
  height: 100%;
}
.kiwi-messagelist-item {
  /* Allow child elements to make use of margins+padding within messagelist items */
  overflow: hidden;
}
.kiwi-messagelist-message {
  padding: 0 10px;
  /* some message highlights add a left border so add a default invisble one in preperation */
  border-left: 3px solid transparent;
  overflow: hidden;
  line-height: 1.5em;
  margin: 0;
}
.kiwi-messagelist-message-mode,
.kiwi-messagelist-message-traffic {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
/* Start of the not connected message styling */
.kiwi-messagelist-message-connection {
  padding: 0;
  text-align: center;
  font-weight: bold;
  border: none;
  margin: 0;
}
.kiwi-messagelist-message-connection .kiwi-messagelist-body {
  font-size: 1.2em;
  height: auto;
  line-height: normal;
  text-align: center;
  cursor: default;
  display: inline-block;
  padding: 0.5em 1em;
  margin: 1em auto 1em auto;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.kiwi-messagelist-message-connection .kiwi-messagelist-time,
.kiwi-messagelist-message-connection .kiwi-messagelist-nick {
  display: none;
}
/* Remove the styling for none user messages, as they make the page look bloated */
.kiwi-messagelist-message-mode,
.kiwi-messagelist-message-traffic,
.kiwi-messagelist-message-connection {
  padding: 0.1em 0.5em;
  min-height: 0;
  line-height: normal;
  margin: 1em 0.5em;
  text-align: left;
}
/* Remove the min height from the message, as again, makes the page look bloated */
.kiwi-messagelist-body {
  min-height: 0;
  text-align: left;
  line-height: 1.5em;
  font-size: 1.05em;
  margin: 0;
  padding: 0;
}
/* Channel messages - e.g 'server on #testing22 ' message and such */
.kiwi-messagelist-message-mode,
.kiwi-messagelist-message-traffic,
.kiwi-messagelist-message-nick {
  padding: 5px  0 5px 0;
  margin: 10px 0;
  opacity: 0.85;
  text-align: center;
  border: none;
}
.kiwi-messagelist-message-mode:hover,
.kiwi-messagelist-message-traffic:hover,
.kiwi-messagelist-message-nick:hover {
  opacity: 1;
}
/* Absolute position the time on these messages so it's not above the message, it looks awful */
.kiwi-messagelist-message-mode .kiwi-messagelist-time,
.kiwi-messagelist-message-traffic .kiwi-messagelist-time {
  position: absolute;
  top: 1px;
  right: 10px;
}
.kiwi-messagelist-message--authorrepeat {
  border-top: none;
}
.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-nick,
.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-time {
  /* Setting the opacity instead visible:none ensures it's still selectable when copying text */
  opacity: 0;
  cursor: default;
}
.kiwi-container--sidebar-open .kiwi-messagelist::after {
  content: '';
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  pointer-events: none;
}
.kiwi-container--sidebar-open.kiwi-container--no-sidebar .kiwi-messagelist::after {
  width: 0;
  height: 0;
  display: none;
  pointer-events: inherit;
  position: static;
  z-index: 0;
}
.kiwi-messagelist-scrollback {
  text-align: center;
  padding: 5px;
}
.kiwi-messagelist-seperator + .kiwi-messagelist-message {
  border-top: none;
}
.kiwi-messagelist-message--blur {
  opacity: 0.5;
}
.kiwi-messagelist-nick {
  text-align: right;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: top;
  cursor: pointer;
  padding: 2px 4px;
}
.kiwi-messagelist-message-traffic .kiwi-messagelist-nick {
  display: none;
}
.kiwi-messagelist-seperator {
  text-align: center;
  display: block;
  margin: 1em 0;
}
.kiwi-messagelist-seperator > span {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0 1em;
}
.kiwi-messagelist-seperator::after {
  content: "";
  display: block;
  position: relative;
  top: -0.8em;
}
/** Displaying an emoji in a message */
.kiwi-messagelist-emoji {
  width: 1.3em;
  display: inline-block;
  pointer-events: none;
  vertical-align: middle;
}
@-webkit-keyframes emojiIn {
0% {
    -webkit-transform: scale(0);
            transform: scale(0);
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
}
@keyframes emojiIn {
0% {
    -webkit-transform: scale(0);
            transform: scale(0);
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
}
.kiwi-messagelist-emoji--single {
  -webkit-animation: 0.1s ease-in-out 0s 1 emojiIn;
          animation: 0.1s ease-in-out 0s 1 emojiIn;
  font-size: 2em;
}
/** Message structure */
.kiwi-messagelist-body .kiwi-nick {
  cursor: pointer;
}
.kiwi-messagelist-nick:hover {
  overflow: visible;
  width: auto;
}
/* Topic changes */
.kiwi-messagelist-message-topic {
  border-radius: 5px;
  margin: 18px;
  margin-left: 0;
  padding: 5px;
  text-align: center;
  position: relative;
  min-height: 0;
  display: block;
}
.kiwi-messagelist-message-topic .kiwi-messagelist-body {
  min-height: 0;
  margin: 0;
}
.kiwi-messagelist-message-topic .kiwi-messagelist-body::before {
  display: none;
}
.kiwi-messagelist-message-topic.kiwi-messagelist-message-topic .kiwi-messagelist-time {
  display: none;
}
.kiwi-messagelist-message-topic.kiwi-messagelist-message-topic .kiwi-messagelist-nick {
  display: none;
}
/* Actions */
.kiwi-messagelist-message-action .kiwi-messagelist-message-body {
  font-style: italic;
}
/* Traffic (joins, parts, quits, kicks) */
.kiwi-messagelist-message-traffic.kiwi-messagelist-message-traffic .kiwi-messagelist-nick {
  display: none;
}
.kiwi-messagelist-message-traffic .kiwi-messagelist-body {
  font-style: italic;
}
.kiwi-messagelist-message-action.kiwi-messagelist-message-action .kiwi-messagelist-nick {
  display: none;
}
/* MOTD */
.kiwi-messagelist-message-motd {
  font-family: monospace;
}
/* Links */
.kiwi-messagelist-message-linkhandle {
  margin-left: 4px;
  font-size: 0.8em;
}
.kiwi-wrap--touch .kiwi-messagelist-message-linkhandle {
  display: none;
}
@media screen and (max-width: 700px) {
.kiwi-messagelist-message,
  .kiwi-messageinfo {
    margin: 0;
}
}

.kiwi-bufferkey {
  box-sizing: border-box;
  text-align: center;
  padding: 10% 0;
  margin: 10px 0 0 0;
  transition: background-color 0.3s;
}
.kiwi-bufferkey label {
  width: 340px;
  display: block;
  margin: 0 auto 10px auto;
  font-size: 1.2em;
  text-align: left;
}
.kiwi-bufferkey input[type="password"] {
  height: 40px;
  padding: 0 10px;
  color: #000;
  border: none;
  width: 320px;
  font-size: 1.2em;
  margin: 0 0 20px 0;
}
.kiwi-bufferkey-buttons {
  width: 100%;
  text-align: center;
  font-size: 1.6em;
}
.kiwi-bufferkey-buttons span:hover {
  text-decoration: underline;
}
.kiwi-bufferkey-button {
  width: auto;
  display: inline-block;
  font-size: 0.8em;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 4px;
  padding: 5px 20px;
  line-height: 40px;
}
.kiwi-bufferkey-button:hover {
  transition: all 0.2s;
}
.kiwi-bufferkey-button i {
  float: left;
  font-size: 1.6em;
  line-height: 40px;
  margin-right: 0.4em;
}
@media screen and (max-width: 1024px) {
.kiwi-bufferkey-button {
    clear: both;
    margin: 0 5% 1em;
    font-size: 1em;
    display: block;
    padding: 0.6em 0.8em 0.6em 0.4em;
    width: 90%;
    box-sizing: border-box;
}
.kiwi-bufferkey-button i {
    display: none;
}
}
@media screen and (max-width: 769px) {
.kiwi-bufferkey {
    font-size: 0.8em;
}
.kiwi-bufferkey-button {
    font-size: 0.7em;
}
}

.kiwi-notconnected {
  box-sizing: border-box;
  text-align: center;
  padding: 10% 0;
  margin: 10px 0 0 0;
  transition: background-color 0.3s;
}
.kiwi-notconnected-bigicon {
  display: inline-block;
  width: 100%;
  margin: 0 0 1em 0;
}
.kiwi-notconnected-bigicon i {
  font-size: 4em;
  cursor: default;
}
.kiwi-notconnected-caption {
  display: block;
  width: 100%;
  font-size: 1.6em;
  cursor: default;
  margin: 0 0 0.5em 0;
}
.kiwi-notconnected-buttons {
  width: 100%;
  text-align: center;
  font-size: 1.6em;
  padding-top: 1em;
}
.kiwi-notconnected-button {
  width: auto;
  display: inline-block;
  padding: 0.8em 1em 0.8em 0.6em;
  font-size: 0.8em;
  border-radius: 0.4em;
  margin: 0 0.8em;
  cursor: pointer;
  transition: all 0.3s;
}
.kiwi-notconnected-button:hover {
  transition: all 0.2s;
}
.kiwi-notconnected-button i {
  float: left;
  font-size: 1.6em;
  line-height: 0.8em;
  margin-right: 0.4em;
}
.kiwi-notconnected-button-settings {
  font-size: 0.6em;
  clear: both;
  display: block;
  max-width: 160px;
  margin: 1em auto;
}
.kiwi-notconnected-button-settings i {
  line-height: 1em;
}
@media screen and (max-width: 1024px) {
.kiwi-notconnected-caption {
    font-size: 1em;
}
.kiwi-notconnected-button {
    clear: both;
    margin: 0 5% 1em;
    font-size: 1em;
    display: block;
    padding: 0.6em 0.8em 0.6em 0.4em;
    width: 90%;
    box-sizing: border-box;
}
.kiwi-notconnected-button-settings {
    font-size: 1em;
    max-width: none;
}
.kiwi-notconnected-button i {
    display: none;
}
}
@media screen and (max-width: 769px) {
.kiwi-notconnected {
    font-size: 0.8em;
}
.kiwi-notconnected-button {
    font-size: 0.7em;
}
}

.kiwi-messagelist-message--compact {
  position: relative;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-message-privmsg:hover,
.kiwi-messagelist-message--compact .kiwi-messagelist-message-action:hover,
.kiwi-messagelist-message--compact .kiwi-messagelist-message-notice:hover {
  cursor: pointer;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-message--blur {
  opacity: 0.5;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-nick {
  width: 110px;
  min-width: 110px;
  display: inline-block;
  left: 0;
  top: -1px;
  position: absolute;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-nick:hover {
  width: auto;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-nick .kiwi-messagelist-time {
  margin-right: 10px;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-time {
  display: inline-block;
  float: right;
  font-size: 12px;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-body {
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-left: 120px;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-body a {
  word-break: break-all;
}
.kiwi-messagelist-message--compact .kiwi-messageinfo {
  padding-left: 130px;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-traffic {
  margin: 0;
  padding: 1px 0;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-traffic .kiwi-messagelist-body {
  margin-left: 131px;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-connection {
  text-align: center;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-connection .kiwi-messagelist-nick,
.kiwi-messagelist-message--compact.kiwi-messagelist-message-connection .kiwi-messagelist-time {
  display: none;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-connection .kiwi-messagelist-body {
  display: inline-block;
  margin-left: auto;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-topic {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  margin: 5px 0;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-topic .kiwi-messagelist-body {
  padding-right: 0;
  max-width: 95%;
  margin-left: 20px;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-time,
.kiwi-messagelist-message--compact.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-nick {
  display: none;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message--unread {
  opacity: 1;
}
@media screen and (max-width: 700px) {
.kiwi-messagelist-message--compact {
    padding: 5px;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-nick {
    display: inline;
    width: auto;
    min-width: auto;
    float: left;
    position: static;
    padding-left: 0;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-time {
    text-align: right;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-body {
    float: left;
    width: 100%;
    margin-left: 0;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message--unread .kiwi-messagelist-body {
    padding-left: 10px;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-message--authorrepeat .kiwi-messagelist-nick {
    display: none;
}
.kiwi-messagelist-message--compact .kiwi-messageinfo {
    padding-left: 2px;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-traffic .kiwi-messagelist-body {
    margin-left: 0;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-traffic {
    margin-left: 10px;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-traffic.kiwi-messagelist-message--unread {
    margin-left: 0;
    padding-left: 10px;
}
}
.kiwi-messagelist-message--compact .kiwi-messagelist-message-traffic .kiwi-messagelist-nick {
  display: none;
}
.kiwi-messagelist-item:last-of-type {
  margin-bottom: 5px;
}

.kiwi-messageinfo {
    display: block;
    padding-top: 1em;
    padding-bottom: 1em;
    position: relative;
}
.kiwi-messageinfo-urls {
    margin-top: 5px;
    padding-top: 5px;
}
.kiwi-messageinfo-url {
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.kiwi-messageinfo-url .u-link {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin: 0.4em 1em 0 1em;
}
.kiwi-messageinfo-close {
    position: absolute;
    right: 20px;
    bottom: 5px;
    font-size: 1.3em;
    cursor: pointer;
}

.kiwi-messagelist-message--modern {
  border-left: 7px solid transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 0 20px;
  margin-left: 0;
  padding: 15px 10px;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-traffic .kiwi-messagelist-modern-left {
  display: none;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorfirst.kiwi-messagelist-message-topic {
  padding: 10px 20px;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorrepeat {
  margin-top: 0;
  padding-top: 0;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-modern-right {
  padding-top: 0;
}
.kiwi-messagelist-message-modern.kiwi-messagelist-message-topic,
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorrepeat.kiwi-messagelist-message-topic {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 10px 2.5%;
  width: 95%;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-topic {
  margin: 10px 20px 10px 20px;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-topic .kiwi-messagelist-modern-left {
  display: none;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-message-topic .kiwi-messagelist-modern-left,
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorrepeat.kiwi-messagelist-message-topic .kiwi-messagelist-modern-left {
  display: none;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-modern-avatar {
  display: none;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-nick,
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-time {
  display: none;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-message-traffic .kiwi-messagelist-modern-right,
.kiwi-messagelist-message--modern .kiwi-messagelist-message-mode .kiwi-messagelist-modern-right {
  float: left;
  margin-left: 0;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-message-mode .kiwi-messagelist-nick {
  display: none;
}
/* Connection styling */
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection {
  padding: 0;
  box-sizing: border-box;
  margin: 10px auto;
  width: 100%;
  border: none;
  opacity: 1;
  border-left: none;
  text-align: center;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection .kiwi-messagelist-time,
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection .kiwi-messagelist-nick {
  display: none;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection .kiwi-messagelist-body {
  line-height: 30px;
  font-weight: 100;
  margin: 0 auto;
  border-radius: 4px;
  display: inline-block;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection .kiwi-messagelist-message {
  margin-bottom: 0;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection .kiwi-messagelist-modern-left {
  display: none;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection .kiwi-messagelist-modern-right {
  margin-left: 0;
  padding: 0;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-body {
  white-space: pre-wrap;
  word-wrap: break-word;
  display: block;
  margin-left: 0;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-body a {
  word-break: break-all;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-modern-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-modern-right {
  margin-left: 5px;
  padding-top: 0;
  width: 100%;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-modern-avatar {
  text-transform: uppercase;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  color: #fff;
  margin-top: 3px;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-nick {
  float: left;
  width: auto;
  text-align: left;
  padding: 0;
  font-size: 1.1em;
  padding-right: 10px;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-time {
  margin: 0 10px 0 0;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  padding: 0;
  opacity: 0.8;
  cursor: default;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-item .kiwi-messagelist-body {
  margin-bottom: 10px;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-traffic {
  margin-right: 0;
  padding-left: 60px;
}
@media screen and (max-width: 769px) {
.kiwi-messagelist-message--modern .kiwi-messagelist-modern-left {
    width: 10px;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-privmsg .kiwi-messagelist-modern-left,
  .kiwi-messagelist-message-notice .kiwi-messagelist-modern-left {
    display: none;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-modern-right {
    margin-left: 0;
}
.kiwi-messagelist-message--modern {
    margin: 0;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    border: none;
    background: #42b992;
    width: 100%;
    border-radius: 0;
    opacity: 0.8;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection .kiwi-messagelist-body {
    line-height: 50px;
    font-weight: 600;
    padding: 0;
}
.kiwi-messagelist-message-action .kiwi-messagelist-modern-left {
    display: none;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-modern-avatar {
    display: none;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-traffic {
    padding-left: 10px;
}
}

.kiwi-serverview {
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}
.kiwi-serverview .kiwi-messagelist {
    padding-top: 0;
}
.kiwi-serverview-inner {
    /* Mobile safari can't work out the height for scrolling to work without this wrapper element */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.kiwi-serverview-alerts {
    margin-bottom: 1em;
}


.kiwi-networksettings {
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto;
  padding: 0;
}
.kiwi-networksettings .kiwi-title {
  float: left;
  width: 100%;
  line-height: 45px;
  height: 46px;
  padding: 0 10px;
  box-sizing: border-box;
  text-align: left;
  font-weight: 600;
  cursor: default;
}
.kiwi-networksettings span {
  text-align: center;
  width: 46px;
  line-height: 46px;
  margin: 0 5px 0 -10px;
  font-weight: 600;
  font-size: 1.2em;
}
.kiwi-networksettings .u-form {
  max-width: 400px;
  display: block;
  margin: 1em auto;
  padding: 0;
  height: auto;
  overflow: hidden;
  clear: both;
  border-radius: 2px;
}
.kiwi-networksettings .u-form span {
  display: inline-block;
  line-height: 25px;
  width: auto;
  font-weight: 500;
  font-size: 1em;
  text-align: left;
  max-width: none;
  top: 10px;
}
.kiwi-networksettings .u-form .input-text--reveal-value span {
  top: -14px;
  font-size: 1em;
  font-size: 0.8em;
}
.kiwi-networksettings input[type='text'],
.kiwi-networksettings input[type='password'],
.kiwi-networksettings input[type='email'],
.kiwi-networksettings textarea,
.kiwi-networksettings .input-text input {
  clear: both;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  line-height: 40px;
  box-sizing: border-box;
  border-radius: 1px;
  min-height: none;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: none;
}
.kiwi-networksettings .input-text-c {
  bottom: auto;
  height: 40px;
  line-height: 40px;
  text-align: center;
  top: 19px;
  right: 15px;
}
.kiwi-networksettings .kiwi-show-advanced-title {
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-bottom: -10px;
}
.kiwi-networksettings .kiwi-networksettings-advanced {
  margin-top: 10px;
}
.kiwi-networksettings .kiwi-networksettings-advanced .kiwi-appsettings-showraw-label {
  margin-left: 5px;
}
.kiwi-networksettings .kiwi-padded-form-element-container {
  float: left;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.kiwi-networksettings .kiwi-padded-form-element-container label {
  margin: 0;
}
.kiwi-networksettings .kiwi-padded-form-element-container .input-text {
  padding-top: 0;
}
.kiwi-networksettings .kiwi-padded-form-element-container.kiwi-dangerzone {
  text-align: center;
}
.kiwi-networksettings-advanced-container {
  padding: 20px 0;
}
.kiwi-networksettings-advanced-container span {
  margin-left: 0;
}
.kiwi-networksettings-advanced-container .u-form-block label {
  float: left;
}
.kiwi-networksettings .input-text .input-text-label {
  margin-left: -5px;
}
.kiwi-networksettings .kiwi-networksettings-connection-password {
  float: left;
  width: 100%;
}
.kiwi-networksettings .kiwi-networksettings-connection-password .input-text {
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.kiwi-networksettings .kiwi-networksettings-server-types-info {
  float: left;
  width: 100%;
  text-align: left;
  clear: both;
}
.kiwi-networksettings .kiwi-networksettings-server-types {
  text-align: center;
}
.kiwi-networksettings .kiwi-networksettings-server-types .kiwi-network-type-button {
  margin: 0 10px 0 10px;
  display: inline-block;
  line-height: 35px;
  padding: 0 10px;
  border: 1px solid;
  transition: all 0.3s;
  border-radius: 4px;
}
.kiwi-networksettings .kiwi-networksettings-username label {
  display: none;
}
.kiwi-networksettings .kiwi-customserver-tls {
  cursor: pointer;
  top: 6px;
}
.kiwi-networksettings .kiwi-customserver-tls-lock {
  font-size: 1.4em;
  opacity: 0;
  left: 3px;
}
.kiwi-networksettings .kiwi-customserver-tls--enabled .kiwi-customserver-tls-lock {
  opacity: 1;
}
.kiwi-networksettings .kiwi-customserver-tls-minus {
  font-size: 1.4em;
  top: 0;
  left: 3px;
}
.kiwi-networksettings .kiwi-connect-to-newnetwork {
  width: auto;
  margin: -10px auto 0 auto;
  border-radius: 3px;
  display: block;
  cursor: pointer;
  padding: 0 10px;
  line-height: 35px;
}
.kiwi-networksettings .kiwi-connect-to-newnetwork:hover {
  opacity: 1;
}
.kiwi-networksettings-section {
  float: left;
  box-sizing: border-box;
  width: 100%;
}
.kiwi-networksettings-close {
  float: right;
}
.kiwi-networksettings form .input-text {
  margin-bottom: 10px;
}
.kiwi-networksettings-error {
  text-align: center;
  margin: 1em;
  padding: 0.3em;
}
.kiwi-networksettings-error span {
  display: block;
  font-style: italic;
}
.kiwi-networksettings-server-types a {
  margin-right: 1em;
}
.kiwi-networksettings-server-types-info {
  font-size: 0.9em;
  font-style: italic;
}
.kiwi-networksettings-connection-address {
  width: 70%;
  display: inline-block;
}
.kiwi-networksettings-connection-port {
  width: 25%;
  float: right;
}
.kiwi-networksettings-connection-port span.input-text-label {
  top: -16px;
}
.kiwi-networksettings-advanced h3 {
  transition: all 0.3s;
}
.kiwi-networksettings-advanced h3:hover {
  cursor: pointer;
}
.kiwi-networksettings-danger h3 {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 0.5em;
}
.kiwi-networksettings-danger label {
  float: left;
  margin: 0;
  width: 100%;
}
.kiwi-networksettings-danger .u-button-warning {
  width: auto;
  height: 30px;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  padding: 0 10px;
  opacity: 0.8;
  margin: 0 auto;
  transition: all 0.3s;
}
.kiwi-networksettings-danger .u-button-warning:hover {
  opacity: 1;
}
@media screen and (max-width: 769px) {
.kiwi-networksettings {
    z-index: 100;
}
}

.kiwi-channellist {
    box-sizing: border-box;
}
.kiwi-channellist-nav {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.kiwi-channellist-pagination {
    display: inline-block;
    margin: 0 2em;
    font-size: 1.2em;
}
.kiwi-channellist-pagination a {
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
}
.kiwi-channellist-search {
    display: inline-block;
}
.kiwi-channellist-info {
    text-align: center;
    padding: 2em 0;
}
.kiwi-channellist table {
    border: none;
    border-collapse: collapse;
}
.kiwi-channellist table tbody td {
    padding: 2px 1em;
}
.kiwi-channellist tr td:first-child {
    white-space: nowrap;
}
.kiwi-channellist-users {
    display: inline-block;
    width: 80px;
    padding: 2px 0;
    border-radius: 3px;
    text-align: center;
}

.kiwi-controlinput {
  z-index: 999;
}
.kiwi-controlinput,
.kiwi-controlinput-inner {
  padding: 0;
  box-sizing: border-box;
}
.kiwi-controlinput-inner i {
  font-size: 120%;
}
.kiwi-controlinput-user {
  height: 100%;
  padding: 0 10px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
  line-height: 40px;
}
.kiwi-controlinput-tools {
  line-height: 40px;
  cursor: pointer;
  margin-left: 10px;
}
.kiwi-controlinput-form {
  padding: 8px 0 0 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
}
.kiwi-controlinput-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
}
.kiwi-controlinput-input {
  text-align: left;
  height: 100%;
  outline: none;
  border: none;
}
.kiwi-controlinput-input-wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: visible;
}
.kiwi-controlinput-tool {
  display: inline-block;
  padding: 0 1em;
}
.kiwi-controlinput-tool a {
  cursor: pointer;
}
.kiwi-controlinput-active-tool {
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}
.kiwi-controlinput-selfuser {
  position: absolute;
  bottom: 100%;
  left: 0;
  max-height: 0;
  transition: max-height 0.2s;
  overflow: hidden;
}
.kiwi-controlinput-selfuser--open {
  max-height: 300px;
}
@media screen and (max-width: 769px) {
.kiwi-controlinput {
    z-index: 0;
}
}
@media screen and (max-width: 500px) {
.kiwi-controlinput-user-nick {
    display: none;
}
}

.kiwi-autocomplete {
    box-sizing: border-box;
    overflow-y: auto;
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;
    z-index: 1;
    max-height: 300px;
}
.kiwi-autocomplete-item {
    padding: 5px 2em;
}
.kiwi-autocomplete-item-value {
    font-weight: bold;
}
.kiwi-autocomplete-item-action {
    float: right;
    font-size: 0.9em;
}


.kiwi-inputtools-colours {
  bottom: 100%;
  right: 0;
  overflow: hidden;
  position: absolute;
  height: 30px;
}
.kiwi-inputtools-colours-colour {
  display: block;
  float: left;
  height: 30px;
  width: 30px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid gray;
  margin: 0;
}
.kiwi-inputtools-colours-reset {
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  background: #000;
  color: #cacaca;
}

.kiwi-inputtool-emoji-emoji {
    display: block;
    float: left;
    height: 30px;
    width: 30px;
    cursor: pointer;
    box-sizing: border-box;
    margin: 2px;
    background-size: contain;
}


.kiwi-selfuser {
    box-sizing: border-box;
    padding: 1em;
}
.kiwi-selfuser-nick {
    display: block;
    font-weight: bold;
    font-size: 1.1em;
}
.kiwi-selfuser-host {
    font-style: italic;
}
.kiwi-selfuser-actions {
    margin-top: 1em;
    padding-top: 1em;
}
.kiwi-selfuser-actions .u-input {
    margin-bottom: 10px;
}

.kiwi-mediaviewer {
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    position: relative;
}
.kiwi-mediaviewer-controls {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}
.kiwi-mediaviewer-controls-close {
    padding: 3px 15px;
}
.kiwi-mediaviewer-iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    border: none;
}

html {
    height: 100%;
    margin: 0;
    padding: 0;
}
body {
    height: 100%;
    margin: 0;
    padding: 0;
}
.kiwi-wrap {
    height: 100%;
    overflow: hidden;
}
.kiwi-wrap-error {
    text-align: center;
}


.u-tabbed-view {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100%;
}
.u-tabbed-view-tabs {
    padding-top: 15px;
}
.u-tabbed-view-tab {
    display: inline-block;
    cursor: pointer;
    border-width: 0;
    border-style: solid;
    background: #fff;
    font-weight: 600;
    opacity: 1;
    z-index: 1;
    margin-bottom: -3px;
    position: relative;
    width: auto;
    text-align: left;
    box-sizing: border-box;
    padding: 0.5em 1em;
    transition: border 0.3s;
}
.u-tabbed-view-tab:hover {
    border-bottom-width: 3px;
}
.u-tabbed-view-tab:last-of-type {
    z-index: 1;
    border-radius: 0 4px 0 0;
}
.u-tabbed-view-tab--active {
    border-bottom-width: 3px;
}
.u-tabbed-content {
    overflow: auto;
    height: 100%;
}
@media screen and (max-width: 769px) {
.u-tabbed-view-tabs {
        padding-top: 0;
}
.u-tabbed-view-tab {
        padding: 10px 20px;
        width: auto;
}
}

.input-text {
    position: relative;
    padding-top: 1.2em;
}
.input-text input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    line-height: 1.6em;
    font-size: 0.9em;
}
.input-text-label {
    position: absolute;
    left: 3px;
    top: 1.2em;
    transition: top 0.2s, font-size 0.2s;
    pointer-events: none;
}
.input-text--reveal-value .input-text-label {
    top: -7px;
    font-size: 0.8em;
}
.input-text-c {
    position: absolute;
    right: 0;
    bottom: 0;
}
.input-text-underline {
    border-width: 0;
    border-bottom: 1px solid #a9a9a9;
    position: relative;
}
.input-text-underline-active {
    background: #42b983;
    transition: left 0.3s;
    position: absolute;
    height: 2px;
    bottom: -1px;
    right: 0;
    left: 100%;
}
.input-text--focus .input-text-underline-active {
    left: 0;
}


.kiwi-ircinput {
    box-sizing: border-box;
    position: relative;
    overflow: visible;
    z-index: 1;
}
.kiwi-ircinput-editor {
    white-space: pre;
    overflow-x: hidden;
    outline: none;
}
.kiwi-ircinput-editor:empty:not(:focus)::before {
    content: attr(placeholder);
    cursor: text;
}
.kiwi-ircinput-editor img {
    height: 1em;
}


.input-prompt {
    display: inline-block;
}

.input-confirm {
    display: inline-block;
    padding: 10px;
}

.kiwi-aboutnew {
    position: absolute;
    bottom: 0;
    height: 53px;
    left: 0;
    right: 0;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
}
.kiwi-aboutnew-sep {
    margin-bottom: 10px;
    border-top: 1px solid #ddd;
}
.kiwi-aboutnew-help {
    font-size: 1.9em;
    float: right;
    cursor: pointer;
}
.kiwi-aboutnew-content {
    display: none;
    position: absolute;
    top: 4px;
    left: 0;
    bottom: 53px;
    width: 100%;
    background: #fff;
    text-align: left;
    line-height: 1.6em;
    padding: 2em;
    box-sizing: border-box;
    overflow: auto;
}
.kiwi-aboutnew-content--open {
    display: block;
}
@media screen and (min-width: 700px) {
.kiwi-aboutnew-content {
        padding: 2em 3em;
}
}
.kiwi-sponsor {
    position: fixed;
    z-index: 20;
    bottom: 40px;
    left: 0;
    width: 185px;
    padding: 10px 0;
    font-size: 0.8em;
    color: #e0e0e0;
}
@media screen and (max-width: 600px) {
.kiwi-sponsor {
        display: none;
}
}
.kiwi-sponsor a {
    color: #e0e0e0;
}
.kiwi-sponsor span {
    display: none;
    font-style: italic;
}
.kiwi-sponsor:hover span {
    display: block;
}
.kiwi-sponsor a:hover {
    color: #bbe073;
    text-decoration: none;
}
.kiwi-personal {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    text-align: center;
    padding-top: 1em;
    font-size: 1.2em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.kiwi-personal h1 {
    margin: 0 0 20px 0;
}
.kiwi-personal p {
    margin: 0 0 10px 0;
}
.kiwi-personal button {
    margin: 10px auto 0 auto;
    padding: 0 40px;
    font-size: 1em;
    line-height: 40px;
}

/* Only show the toggle state browser link if on a small screen */
.kiwi-personal-existing-networks {
    display: none;
}
@media screen and (max-width: 500px) {
.kiwi-personal-existing-networks {
        display: inherit;
}
}

